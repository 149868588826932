import { UserProfileImage } from "@/types/user";
import { ApiSuccessResponse } from "./api-http-requests";
import { Chat } from "./chat";
import { ChatMessage } from "./chat-message";
import { Appointment } from "./appointment";
import { AppointmentRescheduleProposal } from "./appointment-reschedule-proposal";

export enum IoEmit {
    JOIN_ROOM = "join_room",
    LEAVE_ROOM = "leave_room"
}
export enum IoPredefinedRooms {
    CALENDAR = "calendar",
    THERAPIST_RECRUITMENT_FVA = "thr_fva",
    THERAPIST_RECRUITMENT = "therapist_recruitment"
}

export enum IoEvent {
    CALENDAR_SLOTS_BOOKED = "Calendar.slots_booked",
    CALENDAR_SLOTS_RELEASED = "Calendar.slots_released",

    CHAT_CREATED = "Chat.created",
    CHAT_UPDATED = "Chat.updated",

    CHAT_MESSAGE_CREATED = "ChatMessage.created",
    CHAT_MESSAGE_DELETED = "ChatMessage.deleted",

    USER_LINK_TOKEN_CLAIMED = "UserLinkToken.claimed",
    USER_LINK_TOKEN_CLAIM_CANCELED = "UserLinkToken.claim_canceled",
    USER_LINK_TOKEN_CANCELED = "UserLinkToken.canceled",
    USER_LINK_TOKEN_CONFIRMED = "UserLinkToken.confirmed",

    P24BLIK_TR_STATUS_UPDATED = "P24Blik.tr_status_updated",

    HELPDESK_MESSAGE_CREATED = "HelpdeskMessage.created",
    HELPDESK_MESSAGE_READ = "HelpdeskMessage.read",

    HELPDESK_THREAD_CREATED = "HelpdeskThread.created",
    HELPDESK_THREAD_UPDATED = "HelpdeskThread.updated",

    APPOINTMENT_CREATED = "Appointment.created",
    APPOINTMENT_ROOM_CREATED = "Appointment.room_created",
    APPOINTMENT_UPDATED = "Appointment.updated",
    APPOINTMENT_ROOM_ENDED = "Appointment.room_ended",

    APPOINTMENT_RESCHEDULE_PROPOSAL_UPDATED = "AppointmentRescheduleProposal.updated",

    THERAPIST_JOB_APPLICATION_ITEM_UPDATED = "TherapistJobApplication.item_updated",

    ME_UPDATED = "Me.updated"
}

export type ApiSocketGeneralResponse = ApiSuccessResponse & {
    msg: string;
};

export type IoUserLinkTokenClaimer = {
    _id: string;
    first_name: string;
    last_name: string;
    profile_image?: UserProfileImage;
};

export type IoUserLinkTokenIssuer = {
    _id: string;
    first_name: string;
    last_name: string;
    profile_image?: UserProfileImage;
};

export type IoUserLinkToken = {
    user_link_token: string;
    claimer?: IoUserLinkTokenClaimer;
    issuer?: IoUserLinkTokenIssuer;
};

export type IoCheckoutSessionSlotsAvailabilityUpdate = {
    slot_ids: string[];
    ref_id: string;
};

export type IoP24BlikTrStatusUpdatePayload = {
    checkout_session?: string;
} & ({ tr_status: "succeeded" } | { tr_status: "failed"; error_key: string });

export type IoChatPayload = {
    chat: Chat;
};

export type IoChatMessagePayload = {
    chat_message: ChatMessage;
};
export type IoDeletedChatMessagePayload = {
    chat_message: string;
};

export type IoAppointmentPayload = {
    appointment: Appointment;
};
export type IoAppointmentRescheduleProposalPayload = {
    appointment_reschedule_proposal: AppointmentRescheduleProposal;
};

export type IoTherapistJobApplicationItemUpdatedPayload = {
    therapist_job_application: string;
};
