<template>
    <teleport to="body">
        <transition name="fade">
            <div
                v-if="is_open"
                @click="onPressCancel"
                class="fullscreen-overlay__backdrop"
                :style="{ zIndex: backdrop_z_index }"
            ></div>
        </transition>
        <transition name="fullscreen-overlay-slide-right">
            <div
                v-if="is_open"
                :style="{ zIndex: fo_dialog_z_index, maxWidth: `${parsed_width}px` }"
                class="fullscreen-overlay"
                v-bind="$attrs"
                ref="fo_dialog_ref"
            >
                <div class="fullscreen-overlay__main">
                    <slot />
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue";
import { getMaxZIndex } from "@/helpers/layout";
import { useDialogs } from "@/helpers/dialogs";

export type FullscreenOverlayProps = {
    modelValue: boolean;
    persistent?: boolean;
    width?: number | string;
};

/*###########
### SETUP ###
###########*/
defineOptions({
    inheritAttrs: false
});

const props = withDefaults(defineProps<FullscreenOverlayProps>(), {
    fullscreen: false,
    modelValue: false,
    persistent: false,
    width: 600
});

const emit = defineEmits<{
    (e: "update:modelValue", v: boolean): void;
    (e: "cancel"): void;
    (e: "keydown", v: KeyboardEvent): void;
}>();

/*###############
### VARIABLES ###
###############*/
const is_open = ref<boolean>(false);
const fo_dialog_ref = ref<HTMLElement | null>(null);
const backdrop_z_index = ref(1);
const fo_dialog_z_index = ref(2);
let od_regid: string = "";

/*###########
### HOOKS ###
###########*/
const { registerOpenedDialog, unregisterOpenedDialog } = useDialogs();

/*##############
### COMPUTED ###
##############*/
const parsed_width = computed(() => {
    const PV = parseInt(props.width.toString());
    if (isNaN(PV)) return 600;
    return PV;
});

/*##############
### WATCHERS ###
##############*/
watch(
    () => props.modelValue,
    (nv, ov) => {
        if (nv === ov) return;

        if (nv === true) {
            const MZI = getMaxZIndex();
            backdrop_z_index.value = MZI + 1;
            fo_dialog_z_index.value = MZI + 2;

            od_regid = registerOpenedDialog();
            nextTick(() => {
                is_open.value = true;
            });
        } else {
            unregisterOpenedDialog(od_regid);
            nextTick(() => {
                is_open.value = false;
            });
        }
    },
    {
        immediate: true
    }
);

/*###########
### METHODS ###
###########*/
function onPressCancel() {
    if (!props.persistent) {
        emit("update:modelValue", false);
        emit("cancel");
    }
}

function onKeyDown(e: KeyboardEvent) {
    emit("keydown", e);
    if (e.key === "Escape" && !props.persistent) {
        onPressCancel();
    }
}

/*#####################
### LIFESTYLE HOOKS ###
#####################*/
onMounted(() => {
    document.body.addEventListener("keydown", onKeyDown);
});

onUnmounted(() => {
    document.body.removeEventListener("keydown", onKeyDown);
});
</script>
